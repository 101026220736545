import * as yup from 'yup';

import { removeTimeFromDate, removeUserTimeZoneOffset } from '../../utils/date';
import {
  emailRegExp,
  getSpaceErrorMessage,
  nameRegExp,
  phoneRegExp,
  spaceInsideRegex,
} from '../utils/regex';
import { generateMaxDate } from '../utils/validation';

type BookingFormContext = yup.TestContext & {
  from: yup.TestOptions[];
};

const crateSize = [
  'PP20/K11',
  'PP30',
  'PP40/K29',
  'PP50',
  'PP60',
  'PP70',
  'PP80',
  'PP90',
  'PP100',
];

export const validationSchema = yup.object().shape({
  quoteId: yup.string().required('Quote number is required'),
  consultantId: yup.string().required('Please select booking consultant name'),
  contact: yup
    .object({
      nameFirst: yup
        .string()
        .matches(nameRegExp, 'Please enter valid name')
        .min(2, 'Too Short!')
        .max(40)
        .required('First name is required'),
      nameLast: yup
        .string()
        .matches(nameRegExp, 'Please enter valid last name')
        .min(2, 'Too Short!')
        .max(40)
        .required('Last name is required'),
      phone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone is required'),
      email: yup
        .string()
        .max(50)
        .matches(emailRegExp, 'Please enter valid email')
        .matches(spaceInsideRegex, (value) => getSpaceErrorMessage(value.value))
        .required('Email is required'),
      isDefenceMember: yup.boolean().required('Field is required'),
    })
    .required(),
  petTravellingBy: yup.string().required('Please select pet travelling option'),
  quotedAirline: yup.string().when(['petTravellingBy'], {
    is: (petTravellingBy: string) => petTravellingBy === 'flights',
    then: yup.string().required('Please select Quoted Airline'),
  }),
  departure: yup
    .object({
      airportId: yup.string().required('Please select origin port'),
      isPickup: yup.boolean().required('Field is required'),
      date: yup
        .date()
        .required('Please select preferred departure date')
        .test(
          'departureDate',
          'Date must be greater than today',
          (selectedDate) =>
            (selectedDate && new Date().getTime() < selectedDate.getTime()) ??
            false,
        )
        .max(
          generateMaxDate(new Date(), 24),
          'Please select a date that is not more than 24 months in the future',
        ),

      pickupAddress: yup.object().when('isPickup', {
        is: true,
        then: yup.object().shape({
          isCompletingHomeQuarantine: yup
            .boolean()
            .required('Field is required'),
          streetAddress: yup.string().required('Please enter street address'),
          // streetAddress2: yup
          //   .string()
          //   .required('Please enter street address line 2'),
          city: yup
            .string()
            .when('state', {
              is: (state: string) => state === undefined,
              then: yup.string().required('Please select state first'),
            })
            .required('Please select city'),
          state: yup.string().required('Please select state'),
          postcode: yup
            .string()
            // .test(
            //   'receiver.delivery.postcode',
            //   'Invalid postcode',
            //   (value = '') => {
            //     const defaultStateId = 'AU';
            //     return getPostcodeRegex(defaultStateId).test(value);
            //   },
            // )
            .required('Post code is required'),
        }),
      }),
    })
    .required(),
  arrival: yup
    .object({
      airportId: yup
        .string()
        .required('Please select destination port')
        .test(
          'arrival.airportId',
          'Please select destination port different from departure port',
          (value, context) => {
            const { from } = context as BookingFormContext;
            return from[1]?.value?.departure?.airportId !== value;
          },
        ),
      date: yup
        .date()
        .required('Please select preferred arrival date')
        .test(
          'arrivalDate',
          'Date must be greater than today',
          (selectedDate) =>
            (selectedDate && new Date().getTime() < selectedDate.getTime()) ??
            false,
        )
        .test(
          'compareDepartureDateAndArrivalDAte',
          'Date must be greater or equal departure date',
          (value, context) => {
            const { from } = context as BookingFormContext;
            const departureDate = from[1]?.value?.departure?.date;
            if (departureDate && value) {
              const valueWithoutOffset = removeUserTimeZoneOffset(value);

              return (
                new Date(departureDate).getTime() <=
                valueWithoutOffset.getTime()
              );
            }
            return true;
          },
        )
        .max(
          generateMaxDate(new Date(), 24),
          'Please select a date that is not more than 24 months in the future',
        ),
      isDelivery: yup.boolean().required('Field is required'),
      deliveryAddress: yup.object().when('isDelivery', {
        is: true,
        then: yup.object().shape({
          isCompletingHomeQuarantine: yup
            .boolean()
            .required('Field is required'),
          streetAddress: yup.string().required('Please enter street address'),
          // streetAddress2: yup
          //   .string()
          //   .required('Please enter street address line 2'),
          city: yup
            .string()
            .when('state', {
              is: (state: string) => state === undefined,
              then: yup.string().required('Please select state first'),
            })
            .required('Please select city'),
          state: yup.string().required('Please select state'),
          postcode: yup
            .string()
            // .test(
            //   'receiver.delivery.postcode',
            //   'Invalid postcode',
            //   (value = '') => {
            //     const defaultStateId = 'AU';
            //     return getPostcodeRegex(defaultStateId).test(value);
            //   },
            // )
            .required('Post code is required'),
        }),
      }),
    })
    .required(),
  sender: yup
    .object({
      nameFirst: yup
        .string()
        .matches(nameRegExp, 'Please enter valid name')
        .min(2, 'Too Short!')
        .max(40)
        .required('First name is required'),
      nameLast: yup
        .string()
        .matches(nameRegExp, 'Please enter valid last name')
        .min(2, 'Too Short!')
        .max(40)
        .required('Last name is required'),
      phone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone is required'),
      email: yup
        .string()
        .max(50)
        .matches(emailRegExp, 'Please enter valid email')
        .matches(spaceInsideRegex, (value) => getSpaceErrorMessage(value.value))
        .required('Email is required'),
    })
    .required(),
  receiver: yup
    .object({
      nameFirst: yup
        .string()
        .matches(nameRegExp, 'Please enter valid name')
        .min(2, 'Too Short!')
        .max(40)
        .required('First name is required'),
      nameLast: yup
        .string()
        .matches(nameRegExp, 'Please enter valid last name')
        .min(2, 'Too Short!')
        .max(40)
        .required('Last name is required'),
      phone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone is required'),
      email: yup
        .string()
        .max(50)
        .matches(emailRegExp, 'Please enter valid email')
        .matches(spaceInsideRegex, (value) => getSpaceErrorMessage(value.value))
        .required('Email is required'),
    })
    .required(),
  pets: yup
    .array()
    .of(
      yup.object({
        photos: yup
          .array()
          .min(1, 'Please upload a photo')
          .required('Please upload a photo'),
        name: yup
          .string()
          .matches(nameRegExp, 'Please enter valid name')
          .min(2, 'Too Short!')
          .max(40)
          .required('Pet name is required'),
        breed: yup
          .string()
          // brackets aren't support in nameRegExp but breedId can contain brackets
          //.matches(nameRegExp, 'Please enter valid breed')
          .required('Breed is required'),
        weight: yup
          .number()
          .min(0, 'Min value 0.')
          .max(200, 'Max value 200.')
          .typeError('Pet weight must be a number')
          .required('Pet weight is required'),
        sex: yup.string().required('Pet sex is required'),
        colour: yup.string().required('Pet colour is required'),
        birthdate: yup
          .date()
          .required('Pet birthdate is required')
          .test(
            'petBirthdate',
            'Date must be less than today',
            (selectedDate) =>
              (selectedDate &&
                removeUserTimeZoneOffset(removeTimeFromDate()).getTime() >
                  removeUserTimeZoneOffset(
                    removeTimeFromDate(selectedDate),
                  ).getTime()) ??
              false,
          ),
        crate: yup.object({
          type: yup
            .string()
            .oneOf(['buy', 'hire', 'own'])
            .required('Please select a crate type'),
        }),
        confirmCrateSize: yup
          .string()
          .test(
            'confirmCrateSize',
            'Confirm crate size is required',
            (value) => (value && crateSize.includes(value as string)) || false,
          )
          .required('Confirm crate size is required'),
        microchipNumber: yup.string().required('Microchip number is required'),
        isEnteringABoardingKennel: yup.boolean().required('Field is required'),
        hasMedicinalConditions: yup.boolean().required('Field is required'),
        boarding: yup.object().when('isEnteringABoardingKennel', {
          is: true,
          then: yup.object().shape({
            boardingDate: yup.object().shape({
              start: yup.date().required('Please select a boarding date'),
              end: yup.date().required('Please select a boarding date'),
            }),
            isSocialWithOther: yup
              .string()
              .test(
                'isSocialWithOther',
                'Please select field',
                (value) =>
                  (value && ['yes', 'no'].includes(value as string)) || false,
              )
              .required('Please select field'),
            vaccinationStatus: yup
              .string()
              .test(
                'vaccinationStatus',
                'Please select vaccination status',
                (value) =>
                  (value && ['yes', 'no'].includes(value as string)) || false,
              )
              .required('Please select vaccination status'),
            vaccinationDocument: yup
              .array()
              .min(1, 'Please upload a document')
              .required('Please upload a document'),
          }),
        }),
        medical: yup.object().when('hasMedicinalConditions', {
          is: true,
          then: yup.object().shape({
            listMedicalConditions: yup
              .string()
              .required('Please enter medical conditions'),
            listMedications: yup
              .string()
              .required('Please enter list medications'),
          }),
        }),
      }),
    )
    .required(),
  options: yup.object({
    contactDuringPetFlight: yup.string().required('Field is required'),
    confirmAllInformation: yup
      .boolean()
      .test(
        'confirmAllInformation',
        'Confirm is required',
        (value) => value ?? false,
      )
      .required('Confirm is required'),
    interestedInTVSeries: yup
      .boolean()
      .required('Please select are you interested in'),
    comments: yup.string().when('contactDuringPetFlight', {
      is: (contactDuringPetFlight: string) =>
        contactDuringPetFlight === 'other',
      then: yup.string().required('Please enter additional notes'),
    }),
  }),
});

export const mapFieldToPage = (field: string): number => {
  // Page 1
  const firstPageFields = ['quoteId', 'consultantId'];
  const isFirstPage =
    field.startsWith('contact.') ||
    firstPageFields.some((testField) => field.includes(testField));
  if (isFirstPage) return 1;

  // Page 2
  const secondPageFields = [
    'departure.date',
    'arrival.date',
    'departure.airportId',
    'arrival.airportId',
    'petTravellingBy',
    'quotedAirline',
  ];
  const isSecondPage = secondPageFields.some((testField) =>
    field.startsWith(testField),
  );
  if (isSecondPage) return 2;

  // Page 3
  const thirdPageFields = [
    'sender.nameFirst',
    'sender.nameLast',
    'sender.phone',
    'sender.email',
    'departure.isPickup',
    'departure.pickupAddress.isCompletingHomeQuarantine',
    'departure.pickupAddress.streetAddress',
    'departure.pickupAddress.streetAddress2',
    'departure.pickupAddress.city',
    'departure.pickupAddress.state',
    'departure.pickupAddress.postcode',
  ];
  const isThirdPageFields = thirdPageFields.some((testField) =>
    field.startsWith(testField),
  );
  if (isThirdPageFields) return 3;

  // Page 4
  const fourthPageFields = [
    'receiver.nameFirst',
    'receiver.nameLast',
    'receiver.phone',
    'receiver.email',
    'arrival.isDelivery',
    'arrival.deliveryAddress.isCompletingHomeQuarantine',
    'arrival.deliveryAddress.streetAddress',
    'arrival.deliveryAddress.streetAddress2',
    'arrival.deliveryAddress.city',
    'arrival.deliveryAddress.state',
    'arrival.deliveryAddress.postcode',
  ];

  const isFourthPageFields = fourthPageFields.some((testField) =>
    field.startsWith(testField),
  );
  if (isFourthPageFields) return 4;

  // Page 5
  if (field.startsWith('pets.')) return 5;
  // Page 6 - default
  return 6;
};
